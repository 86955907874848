import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { KeycloakService } from 'keycloak-angular';
import {
    Observable,
    Subject,
    catchError,
    of,
    switchMap,
    takeUntil,
    throwError,
} from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { BaseRequestService } from 'app/_services/base.service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { MyToastrService } from 'app/_services/toastr.service';
@Injectable({ providedIn: 'root' })
export class AuthService {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public _authenticated: boolean = false;
    private _router = inject(Router);
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _bs = inject(BaseRequestService)
    private _activatedRoute = inject(ActivatedRoute);
    private _keycloak = inject(KeycloakService);
    private oauthService = inject(OAuthService);
    
    constructor(
        private toast:MyToastrService
        // private authConfig: AuthConfig,
    ) {
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    updateKeycloakUser(user: any, token: any): void {
        // Set the authenticated flag to true
        this._authenticated = true;
        this.accessToken = token;
        // localStorage.setItem('accessToken', token);
        // Store the user on the user service
        user.avatar = 'images/avatars/profile.png';
        user.status = 'online';
        user.token = jwtDecode(token);
        localStorage.setItem('user', JSON.stringify(user))
        this._userService.user = user;
    }

    updateAccessKey(key: any): void {
        this.accessToken = key.jwtToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        key.id = key.userId;
        key.name = key.userName;
        key.email = key.userEmail;
        key.avatar = 'images/avatars/profile.png';
        key.status = 'online';
        // localStorage.setItem('user', JSON.stringify(key))
        // this._userService.user = key;
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }
    refreshToken(): Observable<any> {
        let key: any = localStorage.getItem('user');
        try {
            key = JSON.parse(key);
            if (key && key.jwtToken) {
                this.updateAccessKey(key);
                return of(true);
            } else {
                return of(false)
            }
        } catch (e) {
            return of(false);
        }
    }

    async refreshKeycloakToken(): Promise<boolean> {
        try {
            if (this._keycloak.isTokenExpired(10)) {
                await this._keycloak.updateToken(30);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Token refresh failed', error);
            this._keycloak.logout();
            return false;
        }
    }

    fetchToken(key: string): void {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                APPLICATION: 'IOTPORTAL',
                portalsecretkey: key,
            }),
        };
        var url = `${environment.metaurl}/accessKey/userDTO`
        // if(window.location.host == 'dev.iothos.bpcl.in'){
        //     url = "https://admin-apidev.bpclcloud9.com/accessKey/userDTO"
        // }
        this._httpClient
            .get(
                url,
                httpOptions
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: any) => {
                if (user.status === 200) {
                    if(user.userDTO.userType === "vendor"){
                        if(user.userDTO.dealerRoId){
                        localStorage.setItem('accessToken', user.userDTO.jwtToken);
                        window.open(`${environment.keycloakURL}/bpclvendor/redirect`, '_self');}
                        else{
                            this.toast.sToast('error', "Missing Vendor details, please contact SO.")
                            setTimeout(() => {
                                window.open('https://metahos.bpclcloud9.com', '_self');
                            }, 2000);
                        }
                        return;
                    }
                    // if(user.userDTO.userPosition !== 'HQA'){
                    //     window.open('https://iothos.bpcl.in/bpclvendor/redirect', '_self');
                    //     return;
                    // }
                    // else if(user.userDTO.userType === 'IFMS'){
                    //     // https://bpclifms.mycybercns.com/sign-in?redirectURL=%2Fassessment
                    //     window.open('https://bpclifms.mycybercns.com/sign-in', '_self');
                    //     return;
                       
                    // }
                    user.userDTO.userPosition = 'HQA'
                    const httpOptionss = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json',
                        }),
                    };

                    this._httpClient
                    .post(
                        `${environment.keycloakURL}/generatetoken`,user.userDTO,
                         httpOptionss
                    )
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((apiuser: any) => {
                        if (apiuser.token) {
                            localStorage.setItem('apitoken', apiuser.token)

                            this.accessToken = apiuser.token
                            var usernow = jwtDecode(apiuser.token);
                            localStorage.setItem('user', JSON.stringify(usernow))
                            // this._userService.user = usernow;
                    this.updateAccessKey(user.userDTO);
                    setTimeout(() => {
                        const redirectURL =
                            // this._activatedRoute.snapshot.queryParamMap.get(
                            //     'redirectURL'
                            // ) || '/dashboard';

                        // Navigate to the redirect url
                        this._router.navigateByUrl('/dealer-retail-outlets');
                    })
                }})


                } else {
                    localStorage.clear(); sessionStorage.clear();
                    this._router.navigateByUrl('/sign-in');
                }
            }, (error: any) => {
                console.log(error);
                this._router.navigateByUrl('/sign-in?e=Unauthorized');
            });
    }

    validatetoken(){
            var data = localStorage.getItem('user')
        const httpOptionss = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        }
        this._bs.doRequest(`https://bpcliothosapi.azurewebsites.net/api/generatetoken?`, 'post', data).subscribe((result: any) => {
                if(result.token){
                var usernow = jwtDecode(result.token);
                localStorage.setItem('user', JSON.stringify(usernow))

                setTimeout(() => {
                    const redirectURL =
                        // this._activatedRoute.snapshot.queryParamMap.get(
                        //     'redirectURL'
                        // ) || '/dashboard';

                    // Navigate to the redirect url
                    this._router.navigateByUrl('/dealer-retail-outlets');
                })}
          }, error =>{
            if(error && error.msg){
                
            }
          })
    }
    

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        const accessToken = localStorage.getItem('accessToken');
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                APPLICATION: 'IOTPORTAL',
                AUTHORIZATION: `Bearer ${accessToken}`,
            }),
        };
        // this._httpClient
        //     .get('https://admin-apidev.bpclcloud9.com/logout', httpOptions)
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: any) => {
        //         localStorage.removeItem('accessToken');
        //         localStorage.removeItem('user');
        //     });
            // Remove the access token from the local storage
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        // if (this._authenticated) {
        //     return of(true);
        // }

        // // Check the access token availability
        // if (!this.accessToken) {
        //     return of(false);
        // }

        // // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists, and it didn't expire, sign in using it
        return of(this.oauthService.hasValidAccessToken());;
    }

    public async authenticate(): Promise<boolean> {
        // this.oauthService.configure(this.authConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.strictDiscoveryDocumentValidation = false;
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();
        this._authenticated = this.oauthService.hasValidAccessToken();
        return this._authenticated;
    }

    updateAuth(state: boolean): void {
        this._authenticated = state;
        if (this._authenticated) {
            
        }
    }
}
